<template>
  <v-card class="mt-sm-8 mt-md-5 mt-7 bg-transparent">
    <div class="card-padding pb-0">
      <h4
        class="text-h4 font-weight-bolder indigo text-gradient mb-2"
        style="display: flex; align-items: center"
      >
        <div class="mr-5">
          <v-img
            src="@/assets/img/logo.png"
            class=""
            style="width: 60px"
          ></v-img>
        </div>
        <div class="ml-1">EchoQuants</div>
      </h4>
      <!-- <p class="mb-0 text-body">输入管理员分配给您的帐号登录</p> -->
    </div>
    <div class="card-padding pb-4">
      <label class="text-xs text-typo font-weight-bolder ms-1">用户名</label>
      <v-text-field
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="用户名"
        v-model="name"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      ></v-text-field>

      <label class="text-xs text-typo font-weight-bolder ms-1">密码</label>
      <v-text-field
        hide-details
        outlined
        background-color="rgba(255,255,255,.9)"
        color="rgba(0,0,0,.6)"
        light
        height="40"
        placeholder="密码"
        v-model="password"
        type="password"
        class="
          input-style
          font-size-input
          border border-radius-md
          placeholder-lighter
          text-color-light
          mb-4
          mt-2
        "
      ></v-text-field>

      <!-- <v-checkbox v-model="checkbox" color="#ff0000" :ripple="false" class="ma-0 checkbox-custom checkbox-thinner" hide-details>
				<template v-slot:label>
					<span class="text-typo text-body-2 ls-0">勾选后能保持较长时间免登录</span>
				</template>
			</v-checkbox> -->

      <v-btn
        elevation="0"
        :ripple="false"
        height="43"
        class="
          font-weight-bold
          text-uppercase text-white
          bg-indigo
          py-2
          px-6
          me-2
          mt-7
          mb-2
          w-100
        "
        color="#5e72e4"
        small
        @click="login()"
      >
        登录
      </v-btn>
      <div class="text-center">
        <!-- <p class="text-sm text-body mt-3 mb-0">
          需要更改密码?
          <a
            href="javascript:;"
            class="indigo text-gradient text-decoration-none font-weight-bold"
            @click="changePwd()"
          >
            点此更改密码
          </a>
        </p> -->
        <p class="text-sm text-body mt-3 mb-0">暂未开放注册</p>
      </div>
    </div>
  </v-card>
</template>
<script>
import User from "@/api/user.js";

export default {
  name: "sign-up-cover",
  data() {
    return {
      name: "",
      password: "",
      //checkbox: false,
    };
  },
  mounted() {
    //console.log(this.userStore);
  },
  methods: {
    login() {
      this.showLoading("登录中，请稍候");
      User.login(this.name, this.password)
        .then((res) => {
          this.showToast("登录成功！", "success", 5000);
          console.log(res);
          // return;

          this.userStore.token = res.data.token_type + " " + res.data.token;
          this.userStore.name = res.data.user_info.name;
          this.userStore.user_id = res.data.user_id;

          //this.userStore.permissions = res.data.permissions;

          // let roles = [];
          // res.data.user_info.roles.forEach((item) => {
          //   roles.push(item.name);
          // });
          // this.userStore.roles = roles;

          this.$router.push({ name: "主页" });
        })
        .catch((err) => {
          this.showToast(err.msg, "danger", 5000);
          console.log(err);
          //this.loginError();
        })
        .finally(() => {
          this.hideLoading();
        });
    },
    jumpTo(toName) {
      this.$router.push({ name: toName });
    },
    changePwd() {
      this.jumpTo("更改密码");
    },
  },
};
</script>
<style scoped></style>
